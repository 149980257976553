'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import useUser from '@haaretz/s-atoms/user';
import useLogout from '@haaretz/s-auth-hooks/use-logout';
import Button from '@haaretz/s-button';
import HtzLink from '@haaretz/s-htz-link';
import Icon from '@haaretz/s-icon';
import useBi from '@haaretz/s-use-bi';
import s9 from 'style9';

// `c` is short for `classNames`
const c = s9.create({
  icon: {
    fontSize: space(4),
    marginInlineStart: space(1),
  },
  loginLogoutBtn: {
    ...mq({
      from: 's',
      value: {
        paddingInlineStart: 0,
        paddingInlineEnd: 0,
      },
    }),
    ':focus': {
      backgroundColor: color('neutral200'),
    },
    ':hover': {
      backgroundColor: color('neutral200'),
    },
  },
  contentLoginLogoutBtn: {
    display: 'flex',
    alignItems: 'center',
    ...typesetter(0),
    ...merge({
      ...mq({ from: 's', until: 'xl', value: { ...typesetter(-1) } }),
      ...mq({ from: 'xl', value: { ...typesetter(-2) } }),
    }),
  },
});

export default function LoginAndLogout() {
  const user = useUser('cookieValue');
  const biAction = useBi();

  const logout = useLogout({
    onSuccess: () => {
      window.location.reload();
    },
    onError: error => {
      console.error(error);
    },
  });

  const onClickLogout = () => {
    biAction({
      feature: 'Account Ruler on Header',
      feature_type: 'Personal',
      campaign_name: 'logout',
      action_id: 53,
    });
    logout.mutate({});
  };

  const onClickLogin = () => {
    biAction({
      campaign_name: 'login',
      campaign_details: 'התחברות',
      feature: 'header_hello_login',
      feature_type: 'Marketing',
      action_id: 51,
    });
  };
  return user.isLoggedIn ? (
    <Button
      variant="brand"
      priority="tertiary"
      data-testid="logout-btn"
      styleExtend={[c.loginLogoutBtn]}
      contentStyleExtend={[c.contentLoginLogoutBtn]}
      onClick={onClickLogout}
    >
      <span>{fork({ default: 'התנתקות', hdc: 'Logout' })}</span>
      <Icon icon="Logout" styleExtend={[c.icon]} />
    </Button>
  ) : (
    <Button
      variant="brand"
      priority="tertiary"
      data-testid="login-btn"
      styleExtend={[c.loginLogoutBtn]}
      contentStyleExtend={[c.contentLoginLogoutBtn]}
      as={HtzLink}
      onClick={onClickLogin}
      href={fork({
        default:
          'https://login.haaretz.co.il/?htm_source=site&htm_medium=navigation&htm_campaign=login&htm_content=login',
        tm: 'https://login.themarker.com/?htm_source=site&htm_medium=navigation&htm_campaign=login&htm_content=login',
        hdc: 'https://login.haaretz.com/?htm_source=site&htm_medium=navigation&htm_campaign=login&htm_content=login',
      })}
    >
      <span>{fork({ default: 'התחברות', hdc: 'Login' })}</span>
      <Icon icon="Login" styleExtend={[c.icon]} />
    </Button>
  );
}
