'use client';

import border from '@haaretz/l-border.macro';
import color from '@haaretz/l-color.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import zIndex from '@haaretz/l-z-index.macro';
import useRenderingKind from '@haaretz/s-atoms/renderingKind';
import useUser from '@haaretz/s-atoms/user';
import Icon from '@haaretz/s-icon';
import { Item } from '@haaretz/s-nav-items-group';
import useBi from '@haaretz/s-use-bi';
import * as React from 'react';
import s9 from 'style9';

import getNavbarItem, { getNavBarItemsStr } from '../../utils/getNavbarItem';
import getUserSubscriptionDetails from '../../utils/getUserSubscriptionDetails';
import Hamburger from '../Hamburger';

const transitionAnimationDuration = '0.4s';

const c = s9.create({
  titleAndIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    transitionProperty: 'transform',
    transitionDuration: transitionAnimationDuration,
    transitionTimingFunction: 'ease-in-out',

    ':active': {
      transitionProperty: 'transform',
      transitionDuration: transitionAnimationDuration,
      transitionTimingFunction: 'ease-in-out',
      transform: 'scale(0.8)',
    },
  },
  title: {
    color: color('neutral1000'),
    ...typesetter(-4),
  },
  icon: {
    fontSize: space(7),
  },
  menuBtnWrapper: {
    backgroundColor: 'transparent',
    height: '100%',
    width: '100%',
  },
  alefWrapper: {
    '--wrapper-size': space(15),
    position: 'relative',
    backgroundColor: color('primary1000'),
    top: space(-4),
    height: 'var(--wrapper-size)',
    width: 'var(--wrapper-size)',
    borderRadius: radius('circle'),
    transform: 'scale(1)', // Add initial state
    transitionProperty: 'transform',
    transitionDuration: transitionAnimationDuration,
    transitionTimingFunction: 'ease-in-out',

    ...border({
      color: color('neutral200'),
      side: 'all',
      spacing: 1,
      style: 'solid',
      width: '2px',
    }),
    zIndex: zIndex('masthead'),
    ':active': {
      transform: 'scale(0.8)',
    },
  },
  menuIcon: {
    height: space(16),
    width: space(16),
    alignSelf: 'center',
    justifySelf: 'center',
    marginInlineStart: space(-1),
    marginInlineEnd: space(-1),
    marginTop: space(-0.75),
    marginBottom: space(-0.75),
  },
  hamburger: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '--mHTxtClr': color('neutral1000'),
  },
  fadeInAnimation: {
    opacity: 1,
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationName: s9.keyframes({
      '0%': { opacity: 0 },
      '100%': { opacity: 1 },
    }),
  },
  bold: {
    fontWeight: 700,
  },
  scaleOut: {
    animationName: s9.keyframes({
      from: {
        transform: 'scale(1)',
        opacity: '1',
      },
      to: {
        transform: 'scale(0)',
        opacity: 0,
      },
    }),
    animationDuration: transitionAnimationDuration,
    animationFillMode: 'forwards',
  },
  scaleIn: {
    animationName: s9.keyframes({
      from: {
        transform: 'scale(0)',
        opacity: '0',
      },
      to: {
        transform: 'scale(1)',
        opacity: '1',
      },
    }),
    animationDuration: transitionAnimationDuration,
    animationFillMode: 'forwards',
  },
});

export interface BottomNavigationItemProps {
  currentSelection: string | null;
  onClick: (params: { hasMenu: boolean; name: string | null; title?: string }) => void;
  enableAnimation?: boolean;
  currentIcon: 'alef' | 'hamburger';
  itemNumber: number;
  onKeyDownCapture: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export default function BottomNavigationItem({
  itemNumber,
  currentSelection,
  currentIcon,
  onClick,
  onKeyDownCapture,
}: BottomNavigationItemProps) {
  const [previousIcon, setPreviousIcon] = React.useState(currentIcon);
  const [previousSelection, setPreviousSelection] = React.useState(currentSelection);

  const [isAnimating, setIsAnimating] = React.useState(false);
  const [enableMenuClosingAnimation, setEnableMenuClosingAnimation] = React.useState(false);

  React.useEffect(() => {
    if (currentSelection === null && previousSelection === 'mobileNavigation') {
      setEnableMenuClosingAnimation(true);
    }
    setPreviousSelection(currentSelection);
    const timeoutId = setTimeout(() => setEnableMenuClosingAnimation(false), 300);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelection]);

  React.useEffect(() => {
    if (currentIcon !== previousIcon && !isAnimating) setIsAnimating(true);
  }, [currentIcon, previousIcon, isAnimating]);

  const renderingKind = useRenderingKind();
  const isBlock = renderingKind === 'blocked';

  const user = useUser('cookieValue');
  const { isPartialSubscriber } = getUserSubscriptionDetails(user);

  const biImpression = useBi('impression');
  const [isPurchaseImpressionSent, setIsPurchaseSent] = React.useState(false);

  const navBatItemsStr = getNavBarItemsStr(user?.userType, isPartialSubscriber);

  const [navbarItem, setNavBarItem] = React.useState(
    getNavbarItem(isBlock ? 'anonymous' : 'paying', isPartialSubscriber, itemNumber)
  );

  function onAnimationEnd() {
    setIsAnimating(false);
    setPreviousIcon(currentIcon);
  }

  const { name, title, hasMenu, iconName, href } = navbarItem || {};

  React.useEffect(() => {
    const newItem = getNavbarItem(user?.userType, isPartialSubscriber, itemNumber);
    setNavBarItem(newItem);

    if (newItem.name === 'purchase') {
      if (isPurchaseImpressionSent) return;
      biImpression({
        feature_type: 'Marketing',
        feature: 'mobile bottom navigation',
        campaign_name: navBatItemsStr,
        action_id: 3,
        campaign_details: newItem?.title ? newItem?.title : '',
      });
      setIsPurchaseSent(true);
    }
  }, [
    biImpression,
    isPartialSubscriber,
    isPurchaseImpressionSent,
    itemNumber,
    navBatItemsStr,
    title,
    user?.userType,
  ]);

  const isMainMenuBtn = name === 'mobileNavigation';
  const isPurchase = name === 'purchase';
  if (isMainMenuBtn) {
    return (
      <Item
        styleExtend={[c.alefWrapper]}
        key={name}
        hasMenu={hasMenu}
        onClick={() => {
          onClick({ name, hasMenu });
        }}
        kind="mainMenu"
        onKeyDownCapture={onKeyDownCapture}
      >
        <div className={s9(c.menuBtnWrapper, c.hamburger)}>
          {currentIcon === 'alef' ? (
            <Icon
              icon={iconName}
              variant="brand"
              inlineStyle={{ '--icon--secondary-color': color('neutral100') }}
              styleExtend={[c.menuIcon, isAnimating && c.scaleIn]}
              onAnimationEnd={onAnimationEnd}
            />
          ) : null}
          {currentIcon === 'hamburger' ? (
            <Hamburger
              isOpen={currentSelection === 'mobileNavigation'}
              enableMenuClosingAnimation={enableMenuClosingAnimation}
              styleExtend={[isAnimating && c.scaleIn]}
              onAnimationEnd={onAnimationEnd}
              location="bottomMobileNavigation"
              inlineStyle={{
                '--mHTxtClr': color('neutral200'),
                '--mHamHWidth': space(6),
                '--mHamHeight': space(1),
                '--mHSpacing': space(2),
              }}
            />
          ) : null}
        </div>
      </Item>
    );
  }
  return (
    <Item
      key={name}
      styleExtend={[c.titleAndIconWrapper, c.fadeInAnimation]}
      href={href}
      hasMenu={hasMenu}
      currentSelection={currentSelection}
      onClick={() => {
        onClick({ name, hasMenu, title });
      }}
      onKeyDownCapture={onKeyDownCapture}
    >
      {iconName && (
        <Icon
          icon={iconName}
          variant="neutral"
          inlineStyle={
            isPurchase
              ? { '--_icn-dflt-c': color('salesBtn') }
              : { '--_icn-dflt-c': color('neutral900') }
          }
          styleExtend={[c.icon]}
        />
      )}
      {title && <span className={s9(c.title, isPurchase && c.bold)}>{title}</span>}
    </Item>
  );
}
