'use client';

import color from '@haaretz/l-color.macro';
import zIndex from '@haaretz/l-z-index.macro';
import useArticleCoverColor from '@haaretz/s-atoms/articleCoverColor';
import LayoutContainer from '@haaretz/s-layout-container';
import s9 from 'style9';

const c = s9.create({
  layout: {
    '--mHTxtClr': color('textDarkAllModes'),
    color: 'var(--mHTxtClr)',
    position: 'relative',
    zIndex: zIndex('masthead'),
  },
  onDark: {
    '--mHTxtClr': color('textLightAllModes'),
  },
  labels: {
    '--mHTxtClr': color('bodyText'),
  },
});

export default function TimelineMasthead({
  children,
  isLabels,
}: {
  children: React.ReactNode;
  isLabels?: boolean;
}) {
  const coverColor = useArticleCoverColor();
  const isOnDark = coverColor === 'black';

  return (
    <LayoutContainer
      as="header"
      styleExtend={[c.layout, isOnDark && c.onDark, isLabels && c.labels]}
    >
      {children}
    </LayoutContainer>
  );
}
