'use client';

import zIndex from '@haaretz/l-z-index.macro';
import * as React from 'react';
import s9 from 'style9';

import type { RelevantDialogAttrs } from '@haaretz/s-modal';
import type { InlineStyles, StyleExtend } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  dialog: {
    zIndex: zIndex('dialog'),
  },
  backdrop: {
    zIndex: zIndex('dialogBackdrop'),
    position: 'absolute',
    top: 0,
    insetInlineStart: 0,
    insetInlineEnd: 0,
    bottom: 0,
  },
});

type DivProps = Omit<React.ComponentPropsWithoutRef<'div'>, 'className' | 'style'>;

export interface DialogProps extends RelevantDialogAttrs {
  /** The Children to be rendered inside `<Dialog>` */
  children?: React.ReactNode;
  /**
   * The ID of the element (usually an h*) that is the title of the dialog.
   *
   * In the **RARE** cases where labeling isn't required, manually pass `null`.
   */
  labelledBy?: null | string;
  /** Controls whether the dialog is open or closed */
  isOpen?: boolean;
  /**
   * A callback that is fired whenever the dialog is opened or closed,
   * and takes an `isOpen` boolean argument indicating if the dialog is open
   */
  onToggle?: (isOpen: boolean) => void;
  /**
   * A callback that's fired whenever a dialog is opened and passed
   * a reference to the underlying `dialog` element.
   */
  onOpen?: (elem: HTMLDialogElement) => null;
  /** pass attrs for the backdrop */
  backdropAttrs?: DivProps;
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyDialog(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <Dialog
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   */
  backdropInlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Dialog styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   */
  backdropStyleExtend?: StyleExtend;
  /**
   * By default, dialogs are closed when their direct
   * `offsetParent` is clicked. When needing to close
   * the dialog on any outside click, setting
   * `closeOnAllOutsideClicks` will attach the listener
   *  to the `window`
   */
  closeOnAllOutsideClicks?: boolean;
}

export default function Dialog({
  ref,
  backdropAttrs,
  backdropInlineStyle,
  backdropStyleExtend = [],
  children = null,
  inlineStyle,
  styleExtend = [],
  isOpen,
  labelledBy,
  onOpen,
  onClose: onCloseProp,
  onToggle,
  closeOnAllOutsideClicks = false,
  ...dialogAttrs
}: DialogProps & React.RefAttributes<HTMLDialogElement>) {
  const currentOpenState = React.useRef(false);
  const backdropRef = React.useRef<HTMLDivElement | null>(null);

  const _dialogRef = React.useRef<HTMLDialogElement>(null);
  const dialogRef = ref ?? _dialogRef;

  const nonDialogNodesRef = React.useRef<NodeListOf<
    HTMLElement & { _prevTabindex: string | null }
  > | null>(null);

  const handleClickOutside = React.useCallback(
    (evt: MouseEvent) => {
      const dialogElem = 'current' in dialogRef && dialogRef.current;
      if (dialogElem && !dialogElem.contains(evt.target as HTMLElement)) {
        dialogElem.close();
      }
    },
    [dialogRef]
  );

  const onClose = React.useCallback(
    (evt: React.SyntheticEvent<HTMLDialogElement>) => {
      if (onCloseProp) onCloseProp(evt);
      if (onToggle) onToggle(false);
    },
    [onToggle, onCloseProp]
  );

  const onEsc = React.useCallback(
    (evt: KeyboardEvent) => {
      const dialogElem = 'current' in dialogRef && dialogRef.current;
      if (dialogElem && evt.key === 'Escape') {
        dialogElem.close();
      }
    },
    [dialogRef]
  );

  React.useEffect(() => {
    const dialogElem = 'current' in dialogRef && dialogRef.current;
    const isBeingOpened = isOpen && !currentOpenState.current;
    let offsetParent: HTMLElement | null;
    let closeWhenClickedOn: typeof offsetParent;
    if (dialogElem && isBeingOpened) {
      dialogElem.show();
      offsetParent = dialogElem.offsetParent as HTMLElement | null;

      closeWhenClickedOn = closeOnAllOutsideClicks ? document.documentElement : offsetParent;
      if (closeWhenClickedOn) {
        closeWhenClickedOn.addEventListener('click', handleClickOutside);
        offsetParent?.addEventListener('keydown', onEsc);
        // To trap the Tab(focus) inside the Dialog component,
        // we map other Nodes inside the offsetParent.
        // We will remove their original tab-index and store it and change it then to "tab-index= -1".
        const dialogNodes = Array.from(closeWhenClickedOn.querySelectorAll('dialog *'));
        nonDialogNodesRef.current = closeWhenClickedOn.querySelectorAll(
          ':not(dialog):not([tabindex="-1"])'
        );
        for (let i = 0; i < nonDialogNodesRef.current.length; i++) {
          const node = nonDialogNodesRef.current[i];
          if (!dialogNodes.includes(node)) {
            node._prevTabindex = node.getAttribute('tabindex');
            node.setAttribute('tabindex', '-1');
            node.style.outline = 'none';
          }
        }
      }

      if (onOpen) onOpen(dialogElem);
      if (onToggle) onToggle(true);
      currentOpenState.current = true;
    }

    return () => {
      closeWhenClickedOn?.removeEventListener('click', handleClickOutside);
      offsetParent?.removeEventListener('keydown', onEsc);

      // Restoring the original tab-index from before
      nonDialogNodesRef?.current?.forEach(node => {
        if (node._prevTabindex) {
          node.setAttribute('tabindex', node._prevTabindex);
          node._prevTabindex = null;
        } else {
          node.removeAttribute('tabindex');
        }
        node.style.outline = 'null';
      });
      currentOpenState.current = false;
    };
  }, [isOpen, handleClickOutside, onEsc, onOpen, onToggle, dialogRef, closeOnAllOutsideClicks]);

  if (!isOpen) return null;

  return (
    <>
      <div
        {...backdropAttrs}
        ref={backdropRef}
        className={s9(c.backdrop, ...backdropStyleExtend)}
        style={backdropInlineStyle}
      />
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,
      jsx-a11y/click-events-have-key-events */}
      <dialog
        ref={dialogRef}
        aria-labelledby={labelledBy || undefined}
        tabIndex={-1}
        onClose={onClose}
        {...dialogAttrs}
        className={s9(c.dialog, ...styleExtend)}
        style={inlineStyle}
      >
        {children}
      </dialog>
    </>
  );
}
