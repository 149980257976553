'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import zIndex from '@haaretz/l-z-index.macro';
import ClickArea from '@haaretz/s-click-area';
import Dialog from '@haaretz/s-dialog';
import HtzLink from '@haaretz/s-htz-link';
import Icon from '@haaretz/s-icon';
import useBi from '@haaretz/s-use-bi';
import getBiDataFromMastheadItem from '@haaretz/s-use-bi/getBiDataFromMastheadItem';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import * as React from 'react';
import s9 from 'style9';

import MobileHeader from '../MobileHeader';

import type { BiDataOverrides } from '@haaretz/s-data-structure-types';
import type { NavigationEntryFragment } from '@haaretz/s-fragments/NavigationEntry';

export interface MobileNavigationMenuProps {
  mainNavigation: NavigationEntryFragment['subEntries'];
  isOpen: boolean;
  onClick?: () => void;
  focusOnFirstItem?: boolean;
}

const c = s9.create({
  mobileDialog: {
    position: 'fixed',
    zIndex: zIndex('masthead'),
    height: '100%',
    width: '100%',
    backgroundImage: `radial-gradient(circle at 57% 0, ${color('mastheadGradient')}, ${color(
      'neutral150'
    )} 94%)` as string,
    border: 'none',
    paddingInlineStart: space(8),
    paddingInlineEnd: space(8),
    paddingTop: space(6),
    display: 'flex',
    flexDirection: 'column',
    bottom: 0,
    transitionProperty: 'transform',
    transitionDuration: '0.5s',
    transitionTimingFunction: 'ease-in-out',
    transform: 'translateY(100%)',
    color: color('bodyText'),
  },
  mobileDialogOpen: {
    transform: 'translateY(0)',
  },
  backdropDialog: {
    zIndex: 0,
  },
  link: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    border: `solid 1px ${color('neutral1200', { opacity: 0.2 })}`,
    borderRadius: radius('small'),
    height: '100%',
  },
  searchTxt: {
    color: `${color('neutral1200', { opacity: 0.8 })}`,
    paddingInlineStart: space(2),
    fontWeight: 400,
    ...typesetter(-1),
  },
  searchIcon: {
    width: space(6),
    height: space(6),
    marginInlineEnd: space(2),
  },
  mobileLi: {
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    marginTop: space(4),
    ...typesetter(1),
  },
  sectionNameWithChildren: {
    marginBottom: space(-2),
  },
  mobileUl: {
    overflow: 'auto',
    marginInlineStart: space(-2),
    paddingInlineStart: space(2),
    marginInlineEnd: space(-2),
    paddingInlineEnd: space(2),
  },
  chevronWrapper: {
    justifySelf: 'start',
    marginBottom: space(2),
    marginTop: space(-3),
  },
  mobileChevron: {
    backgroundColor: color('primary1000'),
    borderRadius: radius('circle'),
    color: color('neutral100'),
    marginInlineStart: space(-2),
    paddingBottom: space(0.5),
    marginInlineEnd: space(2),
    paddingInlineEnd: space(0.5),
    paddingInlineStart: space(0.5),
    paddingTop: space(0.5),
    transform: 'rotate(-270deg)',
    transitionDuration: '0.5s',
    transitionTimingFunction: 'ease-in-out',
  },
  mobileChevronRotate: {
    transitionDuration: '0.5s',
    transitionTimingFunction: 'ease-in-out',
    transform: 'rotate(-90deg)',
  },
  closeIcon: {
    textAlign: 'end',
    backgroundColor: 'transparent',
  },
  closeIconWrapper: {
    display: 'flex',
    justifyContent: 'end',
  },
  sectionsTitle: {
    color: color('primary1000'),
    fontWeight: 700,
    marginTop: space(4),
  },
  mobileSubNavigation: {
    display: 'grid',
    gap: space(4),
    marginTop: space(4),
    marginBottom: space(4),
  },
  mobileSubNavigationLi: {
    ...typesetter(1),
  },
  commercialLi: {
    color: color('promoted300'),
  },
  fadeInAnimation: {
    opacity: 0,
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationDelay: 'var(--animationDelay)',
    animationName: s9.keyframes({
      '0%': { opacity: 0 },
      '100%': { opacity: 1 },
    }),
  },
  searchWrapper: {
    minHeight: space(12),
    marginTop: space(8),
    marginBottom: space(4),
    paddingInlineEnd: space(2),
  },
});

export default function MobileNavigation({
  mainNavigation,
  isOpen,
  onClick,
  focusOnFirstItem,
}: MobileNavigationMenuProps) {
  const biAction = useBi();
  const [subItemsExpanded, setSubItemsExpand] = React.useState<null | number>(null);
  const [isAnimating, setIsAnimating] = React.useState(false);
  const [localIsOpen, setLocalIsOpen] = React.useState(isOpen);
  const firstLinkRef = React.useRef<HTMLAnchorElement>(null);

  React.useEffect(() => {
    if (isOpen) {
      setLocalIsOpen(true);
      setIsAnimating(true);
      if (focusOnFirstItem && firstLinkRef.current) {
        firstLinkRef.current.focus();
      }
    }
  }, [focusOnFirstItem, isOpen]);

  const handleTransitionEnd = () => {
    if (!isOpen || !isAnimating) {
      setLocalIsOpen(false);
      setIsAnimating(false);
    }
  };

  const keyboardEscape = (evt: React.KeyboardEvent<HTMLDialogElement>) => {
    if (['Escape'].includes(evt.key)) {
      setIsAnimating(false);
      onClick && onClick();
    }
  };

  const delayDifferenceBetweenItems = 0.1;

  return (
    <Dialog
      styleExtend={[c.mobileDialog, isAnimating && isOpen && c.mobileDialogOpen]}
      isOpen={isOpen || localIsOpen}
      onTransitionEnd={handleTransitionEnd}
      onKeyDown={keyboardEscape}
      data-testid="mobile-navigation"
    >
      <div className={s9(c.closeIconWrapper)}>
        <button
          className={s9(c.closeIcon)}
          onClick={() => {
            setIsAnimating(false);
            onClick && onClick();
          }}
          data-testid="close-mobile-navigation"
        >
          <Icon icon="close" variant="neutral" />
        </button>
      </div>
      <MobileHeader />
      <div className={s9(c.searchWrapper)}>
        <HtzLink
          href="/ty-search"
          className={s9(c.searchButton)}
          onClick={() => {
            biAction({
              action_id: 143,
              feature: 'Search from Header',
              feature_type: 'Content',
            });
          }}
        >
          <span className={s9(c.searchTxt)}>לחצו לחיפוש</span>
          <Icon icon="search" variant="neutral" styleExtend={[c.searchIcon]} />
        </HtzLink>
      </div>

      <span className={s9(c.sectionsTitle)}>כל המדורים</span>
      <ul className={s9(c.mobileUl)}>
        {mainNavigation?.map((entry, id) => {
          const { title, href, kind, subEntries } = entry || {};
          const hasSubItems = (subEntries?.length ?? 0) > 0;
          const isSubItemsExpended = subItemsExpanded === id;
          const [firstSubItem, secondSubItem, ...remainingSubItems] = subEntries ?? [];

          if (!href || !title) return null;

          const biDataEntry = getBiDataFromMastheadItem(title, kind === 'commercial');

          return (
            <React.Fragment key={title}>
              <HtzLink
                className={s9(c.link, kind === 'commercial' && c.commercialLi)}
                href={href}
                onClick={() => biAction(biDataEntry)}
                ref={id === 0 ? firstLinkRef : null}
              >
                <li className={s9(c.mobileLi, hasSubItems && c.sectionNameWithChildren)}>
                  {title}
                </li>
              </HtzLink>

              {/* subitems navigation */}
              {hasSubItems && (
                <ul className={s9(c.mobileSubNavigation)} data-testid="sub-main-navigation">
                  {[firstSubItem, secondSubItem].map(item => {
                    if (!item || !item.title || !item.href) return null;
                    const biData2SubEntry = getBiDataFromMastheadItem(
                      item.title,
                      item.kind === 'commercial'
                    );
                    return (
                      <li
                        key={item.href}
                        className={s9(
                          c.mobileSubNavigationLi,
                          item.kind === 'commercial' && c.commercialLi
                        )}
                        tabIndex={-1}
                      >
                        <HtzLink
                          className={s9(c.link)}
                          href={item.href}
                          onClick={() => biAction(biData2SubEntry)}
                        >
                          {item.title}
                        </HtzLink>
                      </li>
                    );
                  })}

                  {subItemsExpanded === id &&
                    remainingSubItems.map((item, i) => {
                      if (!item || !item.title || !item.href) return null;
                      const animationDelay = (i + 1) * delayDifferenceBetweenItems;
                      const biDataSubEntry = getBiDataFromMastheadItem(
                        item.title,
                        item.kind === 'commercial'
                      );

                      return (
                        <li
                          key={item.href}
                          className={s9(
                            c.mobileSubNavigationLi,
                            c.fadeInAnimation,
                            item.kind === 'commercial' && c.commercialLi
                          )}
                          style={{ '--animationDelay': `${animationDelay}s` }}
                          tabIndex={-1}
                        >
                          <HtzLink
                            className={s9(c.link)}
                            href={item.href}
                            onClick={() => biAction(biDataSubEntry)}
                          >
                            {item.title}
                          </HtzLink>
                        </li>
                      );
                    })}

                  {remainingSubItems.length ? (
                    <ClickArea
                      styleExtend={[c.chevronWrapper]}
                      rippleSize="extraSmall"
                      onClickCapture={() => setSubItemsExpand(subItemsExpanded === id ? null : id)}
                    >
                      <Icon
                        icon="chevron"
                        styleExtend={[c.mobileChevron, isSubItemsExpended && c.mobileChevronRotate]}
                      />
                      <VisuallyHidden>{fork({ default: 'עוד', hdc: 'Show More' })}</VisuallyHidden>
                    </ClickArea>
                  ) : null}
                </ul>
              )}
            </React.Fragment>
          );
        })}
      </ul>
    </Dialog>
  );
}

export interface NavigationLiItemProps {
  title: string;
  href?: string | null;
  hasSubItems?: boolean;
  focusItem?: number | null;
  firstLi: HTMLElement;
  lastLi: HTMLElement;
  idx: number;
  kind?: string | null;
  subItemsExpanded: number | null;
  setSubItemsExpand: React.Dispatch<React.SetStateAction<number | null>>;
  setFocusItem: React.Dispatch<React.SetStateAction<number | null>>;
  mainNavigationLength: number;
  biData?: BiDataOverrides;
}
