'use client';
import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import usePlatform from '@haaretz/s-atoms/platform';
import useUser from '@haaretz/s-atoms/user';
import Button from '@haaretz/s-button';
import HtzLink from '@haaretz/s-htz-link';
import Icon from '@haaretz/s-icon';
import NoSSR from '@haaretz/s-no-ssr';
import useBi from '@haaretz/s-use-bi';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import dynamic from 'next/dynamic';
import * as React from 'react';
import s9 from 'style9';

import type { NavigationEntryFragment } from '@haaretz/s-fragments/NavigationEntry';

export interface UserMenuWrapperProps {
  navUserMenuItems: NavigationEntryFragment['subEntries'];
  children: React.ReactNode;
}

interface UserMenuProps {
  navUserMenuItems: NavigationEntryFragment['subEntries'];
  isOpen?: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

const DesktopUserMenu = dynamic(
  // @ts-expect-error - Relative import paths need explicit file extensions when '--moduleResolution' is 'nodenext'.
  () => import('./DesktopUserMenu').then(_ => _.default),
  {
    ssr: false,
  }
) as React.ComponentType<UserMenuProps>;

const MobileUserMenu = dynamic(
  // @ts-expect-error - Relative import paths need explicit file extensions when '--moduleResolution' is 'nodenext'.
  () => import('./MobileUserMenu').then(_ => _.default),
  {
    ssr: false,
  }
) as React.ComponentType<UserMenuProps>;

const c = s9.create({
  // Common
  icon: {
    fontSize: space(5),
    verticalAlign: 'bottom',
  },
  userBtn: {
    color: 'var(--mHTxtClr)',
    marginInlineEnd: space(-2),
    paddingInlineEnd: space(2),
    paddingInlineStart: space(2),
    ':hover': {
      '--mHTxtClr': color('neutral1100'),
    },
    ':focus': {
      '--mHTxtClr': color('neutral1100'),
    },
  },
  userBtnDesktop: {
    display: 'none',
    ...merge(
      mq({
        from: 's',
        value: {
          display: 'flex',
        },
      }),
      mq({
        from: 's',
        until: 'l',
        value: {
          paddingInlineEnd: space(1),
          paddingInlineStart: space(0),
        },
      })
    ),
  },
  userBtnMobile: {
    gridColumnEnd: -1,
    ...merge(
      mq({
        from: 's',
        value: {
          display: 'none',
        },
      })
    ),
  },

  // Desktop
  userBtnWhenOpen: {
    pointerEvents: 'none',
  },
  greetingTxt: {
    ...merge({
      ...mq({
        from: 's',
        value: {
          marginInlineStart: space(1),
          ...typesetter(-2),
        },
      }),
      ...mq({
        from: 'l',
        value: {
          ...typesetter(-1),
        },
      }),
      ...mq({
        from: 'xl',
        value: {
          ...typesetter(-2),
        },
      }),
      ...mq({
        from: 'xxl',
        value: { ...typesetter(-3) },
      }),
    }),
  },
  open: {
    ...merge({
      ...mq({
        from: 's',
        value: {
          color: color('primary1000'),
        },
      }),
    }),
  },
  userIcon: {
    ...merge({
      ...mq({
        from: 's',
        value: {
          marginInlineEnd: space(1),
        },
      }),
    }),
  },
  loginBtn: {
    color: 'var(--mHTxtClr)',
    paddingInlineEnd: space(2),
    paddingInlineStart: space(2),
    ...typesetter(-2),

    ':hover': {
      '--mHTxtClr': color('neutral1100'),
    },
    ':focus': {
      '--mHTxtClr': color('neutral1100'),
    },

    ...merge(
      mq({
        until: 's',
        value: {
          display: 'none',
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          ...typesetter(-2),
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          ...typesetter(-1),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          ...typesetter(-2),
        },
      }),
      mq({
        from: 'xxl',
        value: { ...typesetter(-3) },
      })
    ),
  },
  userName: {
    ...merge({
      ...mq({
        until: 'm',
        value: {
          display: 'none',
        },
      }),
    }),
  },
});

export default function UserMenuWrapper({ navUserMenuItems, children }: UserMenuWrapperProps) {
  const platform = usePlatform();
  const [isOpen, setIsOpen] = React.useState(false);

  const isDesktop = platform === 'desktop';
  const isMobile = platform === 'mobile';

  const onClick = () => setIsOpen(!isOpen);

  return (
    <React.Fragment>
      <NoSSR>
        <DesktopUserMenuBtn isOpen={isOpen} onClick={onClick} />
      </NoSSR>
      {isDesktop && (
        <DesktopUserMenu navUserMenuItems={navUserMenuItems} isOpen={isOpen} onClick={onClick}>
          {children}
        </DesktopUserMenu>
      )}
      <MobileUserMenuBtn onClick={onClick} />
      {isMobile && (
        <MobileUserMenu navUserMenuItems={navUserMenuItems} isOpen={isOpen} onClick={onClick}>
          {children}
        </MobileUserMenu>
      )}
    </React.Fragment>
  );
}

interface UserMenuBtnProps {
  isOpen?: boolean;
  onClick: () => void;
}

function DesktopUserMenuBtn({ isOpen, onClick }: UserMenuBtnProps) {
  const biAction = useBi();
  const user = useUser('cookieValue');

  const onClickLogin = () => {
    biAction({
      campaign_name: 'login',
      campaign_details: 'התחברות',
      feature: 'header_hello_login',
      feature_type: 'Marketing',
      action_id: 51,
    });
  };

  const onClickUserBtn = () => {
    biAction({
      feature: 'Account Ruler on Header',
      feature_type: 'Personal',
      action_id: 52,
    });
  };

  return (
    <>
      {user.isLoggedIn ? (
        <Button
          variant="neutral"
          priority="tertiary"
          data-testid="masthead-userMenuBtn"
          onClick={() => {
            onClickUserBtn();
            typeof onClick === 'function' && onClick();
          }}
          styleExtend={[c.userBtn, c.userBtnDesktop]}
          contentStyleExtend={[c.greetingTxt, isOpen && c.open]}
        >
          <Icon icon="user" styleExtend={[c.icon, c.userIcon]} />
          <span className={s9(c.userName)}>
            {user.firstName && `${fork({ default: 'שלום', hdc: 'Hi' })}, ${user.firstName}`}
          </span>
        </Button>
      ) : (
        <Button
          variant="neutral"
          priority="tertiary"
          data-testid="login-btn"
          styleExtend={[c.loginBtn, isOpen && c.userBtnWhenOpen]}
          as={HtzLink}
          onClick={onClickLogin}
          href={fork({
            default:
              'https://login.haaretz.co.il/?htm_source=site&htm_medium=navigation&htm_campaign=login&htm_content=login',
            tm: 'https://login.themarker.com/?htm_source=site&htm_medium=navigation&htm_campaign=login&htm_content=login',
            hdc: 'https://login.haaretz.com/?htm_source=site&htm_medium=navigation&htm_campaign=login&htm_content=login',
          })}
        >
          {fork({ default: 'התחברות', hdc: 'Login' })}
        </Button>
      )}
    </>
  );
}

function MobileUserMenuBtn({ onClick }: UserMenuBtnProps) {
  return (
    <Button
      variant="neutral"
      priority="tertiary"
      styleExtend={[c.userBtn, c.userBtnMobile]}
      onClick={onClick}
    >
      <Icon icon="user" styleExtend={[c.icon]} />
      <VisuallyHidden>{fork({ default: 'פרטי חשבון', hdc: 'Account Details' })}</VisuallyHidden>
    </Button>
  );
}
