'use client';

import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import useArticleCoverColor from '@haaretz/s-atoms/articleCoverColor';
import SingleColumnCenter from '@haaretz/s-single-column-center';
import s9 from 'style9';

const c = s9.create({
  layout: {
    '--mHTxtClr': color('textDarkAllModes'),
    color: 'var(--mHTxtClr)',
    position: 'relative',
    ...merge({
      ...mq({
        until: 'l',
        value: {
          backgroundColor: color('primary300'),
        },
      }),
    }),
  },
  onDark: {
    '--mHTxtClr': color('textLightAllModes'),
  },
  noCoverColor: {
    '--mHTxtClr': color('neutral1100'),
  },
});

export default function QuotesMasthead({ children }: { children: React.ReactNode }) {
  const coverColor = useArticleCoverColor();
  const isOnDark = coverColor === 'black';

  return (
    <SingleColumnCenter
      as="header"
      styleExtend={[c.layout, !coverColor ? c.noCoverColor : isOnDark ? c.onDark : null]}
    >
      {children}
    </SingleColumnCenter>
  );
}
