'use client';

import color from '@haaretz/l-color.macro';
import zIndex from '@haaretz/l-z-index.macro';
import useArticleCoverColor from '@haaretz/s-atoms/articleCoverColor';
import MagazineLayout from '@haaretz/s-magazine-layout';
import s9 from 'style9';

const c = s9.create({
  layout: {
    '--mHTxtClr': color('textDarkAllModes'),
    color: 'var(--mHTxtClr)',
    position: 'relative',
    zIndex: zIndex('masthead'),
    backgroundColor: 'var(--mHBgClr)',
  },
  onDark: {
    '--mHTxtClr': color('textLightAllModes'),
  },
  noCoverColor: {
    '--mHTxtClr': color('neutral1100'),
  },
});

export default function MagazineMasthead({ children }: { children: React.ReactNode }) {
  const coverColor = useArticleCoverColor();
  const isOnDark = coverColor === 'black';

  return (
    <MagazineLayout
      as="header"
      styleExtend={[c.layout, !coverColor ? c.noCoverColor : isOnDark ? c.onDark : null]}
    >
      {children}
    </MagazineLayout>
  );
}
